import { render, staticRenderFns } from "./DetalleOBSComponent.vue?vue&type=template&id=a647a4ba"
import script from "./DetalleOBSComponent.vue?vue&type=script&lang=js"
export * from "./DetalleOBSComponent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports